import type { Genre, Trailer } from '@filmweb/sanity-types';
import { gql } from 'graphql-request';
import { GetServerSideProps } from 'next';
import { groq } from 'next-sanity';
import { useRouter } from "next/router";
import { rgba } from "polished";
import { Fragment, useRef } from "react";
import Ad from 'src/components/ads/Ad';
import ContentWrapper from 'src/components/contentwrapper/ContentWrapper';
import FilmTexts from "src/components/film/FilmTexts";
import FilmTitle from 'src/components/film/FilmTitle';
import FilmTopImage from 'src/components/film/FilmTopImage';
import ImageGalleryPlaceholder from "src/components/film/ImageGalleryPlaceholder";
import KKPromo from "src/components/film/KKPromo";
import MoviePoster from 'src/components/film/MoviePoster';
import Ratings from 'src/components/film/Ratings';
import SimilarStreamingTitles from "src/components/film/SimilarStreamingTitles";
import TrailerContent from 'src/components/film/TrailerContent';
import TrailerWrapper from 'src/components/film/TrailerWrapper';
import FilmwebIdButtonPanel from "src/components/film/filmwebid/FilmwebIdButtonPanel";
import { MovieActivityWrapper } from "src/components/film/filmwebid/MovieActivityContext";
import UserReviews from "src/components/film/filmwebid/UserReviews";
import ProgramDisplay from "src/components/film/program/ProgramDisplay";
import FilmFacts from 'src/components/filmfacts/FilmFacts';
import FilmwebLink from "src/components/filmweblink/FilmwebLink";
import FilmwebNews from "src/components/filmwebnews/FilmwebNews";
import InfoBox from "src/components/infobox/InfoBox";
import PopularMoviesList from "src/components/popularmovieslist/PopularMoviesList";
import FilmSeo from 'src/components/seo/FilmSeo';
import StreamingProviderList from "src/components/streaming/StreamingProviderList";
import StreamingSearchBox from "src/components/streaming/StreamingSearchBox";
import TopOfPageSponsor from "src/components/takoversponsor/TopOfPageSponsor";
import theme from 'src/components/themes/DefaultTheme';
import AdWrapper, { AdUnit } from 'src/lib/contexts/AdContext';
import { LocationWrapper } from "src/lib/contexts/LocationContext";
import ProgramDatesWrapper from 'src/lib/contexts/ProgramDatesWrapper';
import SelectedCinemaWrapper from "src/lib/contexts/SelectedCinemaContext";
import { FRONTPAGE_NEWS, SNIPPET_FILMOMTALE, STREAMING_NEWS, filterOtherNews } from 'src/lib/groq';
import { ImageVersionType, StreamingContentType, StreamingProviderContentType } from 'src/lib/movieinfo/movieinfotypes';
import { useOverriddenAdunits } from "src/lib/server/ads";
import { setDefaultCacheHeaders } from 'src/lib/server/cache';
import { filminfoQuery } from 'src/lib/server/filminfo';
import { ICommonProps, IQueryResultWithNews, getServerData, isPreview, kinoklubbSanityQuery, sanityQuery } from 'src/lib/server/sanity';
import styled from "src/lib/styles/css";
import IFilmnytt from "src/lib/types/Filmnytt";
import { DocType_Filmomtale, IFilmomtale } from 'src/lib/types/Filmomtale';
import { KinoklubbOmtale } from "src/lib/types/Kinoklubb";

//#region [Other]
const PAGE_QUERY: string = groq`
{
	"published": *[_type =="${DocType_Filmomtale}" && mainVersionEDI == $edi] {
		${SNIPPET_FILMOMTALE}
	}[0],
	"news": ${FRONTPAGE_NEWS}
}
`;

const STREAMING_PAGE_QUERY: string = groq`{
    "published": *[_type =="${DocType_Filmomtale}" && mainVersionEDI == $edi]{
        reviews,
        premiere,
        mainVersionEDI
    }[0],
	"news": ${STREAMING_NEWS},

}`;


const KINOKLUBB_PAGE_QUERY: string = groq`
*[_type == "kinoklubbFilmomtale" && edi.current == $edi] {
	_type,
	_id,
	_rev,
	_createdAt,
  	_updatedAt,
	reviewer-> {
		...,
		portrait->
	},
	review-> {
		...,
		author-> {
			...,
			portrait->
		}
	}
}[0]
`;

const STREAMING_QUERY = gql`
query ($streamingId: Int, $edi: String) {
	streamingQuery {
	  getStreamingContent(id: $streamingId, movieId: $edi) {
		__typename
		id
		isSeries
		title
		filmwebMovieId
		hasCinemaShows
		actors
		director
		genres
		sanityImagePosterUrl
		sanityImageWideUrl
		imagesPoster {
			__typename
			height
			width
			url
		}
		imagesWide {
			__typename
			height
			width
			url
		}
		imdbId
		ratingImdb
		ratingTmdb
		ratingRottenTomatoes
		length
		music
		nationality
		originalLanguage
		originalTitle
		photo
		production
		writers
		trailer
		year
		providerContents {
			__typename
			id
			provider {
				id
				logo
				name
			}
			title
			url
		}
		synopsisBodyText
		synopsisIngress
		similarContent {
			__typename
			id
			isSeries
			title
			imagesPoster {
			  __typename
			  height
			  width
			  url
			}
			sanityImagePosterUrl
		  }
	  }
	}
  }
`;
//#endregion

//#region [Other]getServerSideProps
export const getServerSideProps: GetServerSideProps = getServerData(async (context) => {
    setDefaultCacheHeaders(context);
    const edi = context.params?.edi;
    let streamingId = context.params?.streamingId;
    if (streamingId && Array.isArray(streamingId)) {
        // this is to handle old streaming urls like /streamingguide/serie/78048/MedicalPolice
        // which had the title at the end.
        if (streamingId.length > 0) {
            streamingId = streamingId[0];
        }
    }
    if (!edi && !streamingId) {
        console.error("No edi or streaming id present. Returning not found");
        return { notFound: true };
    }
    const isP = isPreview(context);


    const filminfoData = await filminfoQuery(STREAMING_QUERY, { streamingId: Number(streamingId), edi });
    const streamingEdi = filminfoData?.streamingQuery?.getStreamingContent?.filmwebMovieId;
    const cmsDataResult = edi
        ? await sanityQuery<IQueryResultWithNews<IFilmomtale>>(PAGE_QUERY, { edi }, { isPreview: isP })
        : await sanityQuery<IQueryResultWithNews<IFilmomtale>>(STREAMING_PAGE_QUERY, { edi: streamingEdi }, { isPreview: isP, useCdn: true });


    //let skipStreaming = false;
    /* NOTE: Dette gjør at kommende filmer kræsjer. Hvorfor var dette lagt inn?
    const premiere = cmsDataResult?.published?.premiere;
    if (premiere) {
        // make sure no streaming data is included if the movie has a premiere in the future
        if (!premiere.noCinemaRelease && (premiere.expectedPremiereDate || premiere.premiereDate)) {
            const premDate = parse((premiere.premiereDate ?? premiere.expectedPremiereDate)!, "yyyy-MM-dd", new Date());
            if (isFuture(premDate)) {
                skipStreaming = true;
            }
        }
    }*/

    let cmsData: IFilmomtale | undefined = cmsDataResult?.published;

    if (!cmsData && !filminfoData?.streamingQuery?.getStreamingContent) {
        console.error("No CMS data or streaming content. Returning not found");
        return { notFound: true };
    }

    //console.debug("STREAMING DATA", filminfoData)

    let otherNews: IFilmnytt[] | undefined | null = cmsDataResult?.news;
    if (otherNews) {
        // article types other than "filmomtale" willbe a null element in the array, therefore we must filter it
        otherNews = filterOtherNews(otherNews, cmsData?._id)?.slice(0, 3);
    }

    let kinoklubbMovie: KinoklubbOmtale | undefined;

    if (edi && cmsData?.isKinoklubb) {
        kinoklubbMovie = await kinoklubbSanityQuery<KinoklubbOmtale>(KINOKLUBB_PAGE_QUERY, { edi });
    }

    return {
        props: {
            cmsDocument: cmsData ?? null,
            //streamingData: skipStreaming ? null : (filminfoData?.streamingQuery?.getStreamingContent ?? null),
            streamingData: (filminfoData?.streamingQuery?.getStreamingContent ?? null),
            isStreamingContext: !!streamingId,
            otherNews: otherNews ?? null,
            //hasCustomBg: true,
            kinoklubbMovie: kinoklubbMovie ?? null,
            //customBgTargetAdId: "SponsorTrackingPixel"
        }
    };

});
//#endregion

//#region [Props]
export type FilmomtaleProps = {
    cmsDocument: IFilmomtale<true> | null;
    streamingData: StreamingContentType | null;
    isStreamingContext: boolean;
    isAdFree?: boolean;
    otherNews: IFilmnytt[] | null;
    kinoklubbMovie: KinoklubbOmtale | null;
} & ICommonProps;
//#endregion

//#region [Component]
export default function Filmomtale({ cmsDocument, streamingData, isStreamingContext, appData, kinoklubbMovie, isAdFree = false, otherNews, adUnits, hasSponsor, sponsorTopImageMobile, sponsorTopImageDesktop, sponsorClickUrl }: FilmomtaleProps) {
    const reviewsListRef = useRef<HTMLElement>(null);
    const finalAdunits = useOverriddenAdunits(isStreamingContext ? SG_ADS : ADS, adUnits);
    const router = useRouter();
    const hasStreamingOptions = (streamingData?.providerContents?.length ?? 0) > 0;
    const streamingOptionsId = streamingData?.id
    const _streamingData = isStreamingContext ? streamingData : null;



    return (<>
        {!isAdFree && <SInfoBox messageBanner={appData.settings} />}
        <SFilmWrapper>
            <FilmSeo document={cmsDocument} film={_streamingData} isStreamingContext={isStreamingContext} />
            <MovieActivityWrapper edi={cmsDocument?.mainVersionEDI ?? _streamingData?.filmwebMovieId} streamingId={_streamingData?.id}>
                <MovieOptionalLocationWrapper isStreamingContext={isStreamingContext}>
                    <MovieOptionalCinemaWrapper isStreamingContext={isStreamingContext}>
                        <AdWrapper defaultProximity={ADPROXIMITY} ads={finalAdunits} key={router.pathname + ":" + (isStreamingContext ? _streamingData?.id : cmsDocument?.mainVersionEDI)}>
                            <TrailerWrapper>
                                <FilmTopImage cmsImage={cmsDocument?.mainImageV2}
                                    cmsPosterImage={cmsDocument?.mainPosterV2}
                                    streamingImages={_streamingData?.imagesWide as (ImageVersionType[] | undefined)}
                                    streamingPosterImages={_streamingData?.imagesPoster as (ImageVersionType[] | undefined)}
                                    fallbackAltText={cmsDocument?.title ?? _streamingData?.title ?? "Bilde mangler"}
                                    sanityPosterUrl={_streamingData?.sanityImagePosterUrl}
                                    sanityWideImageUrl={_streamingData?.sanityImageWideUrl}
                                    isStreamingContext={isStreamingContext}
                                />
                                <SHeaderContentWrapper>
                                    <SOverlay />
                                    <SButtonsAndTitle>
                                        <FilmwebIdButtonPanel edi={cmsDocument?.mainVersionEDI ?? _streamingData?.filmwebMovieId} streamingId={_streamingData?.id} reviewsListRef={reviewsListRef} />
                                        <TrailerContent trailerId={((cmsDocument?.trailersOverride?.[0] ?? cmsDocument?.trailers?.[0]) as Trailer)?.videoId} streamingTrailer={_streamingData?.trailer} isStreamingContext={isStreamingContext} />
                                        <FilmTitle
                                            title={(cmsDocument?.title ?? _streamingData?.title)!}
                                            ageRating={cmsDocument?.ageRating?.age}
                                            genres={(cmsDocument?.genres as Genre[])?.map(g => g.name) ?? _streamingData?.genres}
                                            length={cmsDocument?.runningTime ?? _streamingData?.length} />

                                    </SButtonsAndTitle>

                                </SHeaderContentWrapper>
                            </TrailerWrapper>

                            {/*hasSponsor && isStreamingContext && <STopOfPageSponsor sponsorText="none" clickUrl={sponsorClickUrl} mobileImages={sponsorTopImageMobile} desktopImages={sponsorTopImageDesktop} />*/}

                            <SBodyContentWrapper>

                                <SProgramContainer>
                                    {!isStreamingContext && <ProgramDatesWrapper>
                                        <ProgramDisplay
                                            edi={cmsDocument?.mainVersionEDI ?? _streamingData?.filmwebMovieId}
                                            movieTitle={cmsDocument?.title ?? _streamingData?.title}
                                            premiere={cmsDocument?.premiere}
                                            isKinoklubbMovie={cmsDocument?.isKinoklubb}
                                            genres={cmsDocument?.genres as Genre[]}
                                            sponsor={hasSponsor ? <STopOfPageSponsor sponsorText="none" clickUrl={sponsorClickUrl} mobileImages={sponsorTopImageMobile} desktopImages={sponsorTopImageDesktop} /> : undefined}
                                        />
                                        {hasStreamingOptions && <SSeeOtherContentLink to={`/streamingguide/film/${streamingOptionsId}`}>Filmen er tilgjengelig på streaming. Klikk her for å se hvor den kan streames.</SSeeOtherContentLink>}
                                    </ProgramDatesWrapper>}
                                    {isStreamingContext && <>
                                        <StreamingProviderList
                                            streamingId={_streamingData!.id}
                                            providers={_streamingData?.providerContents as (StreamingProviderContentType[] | undefined)}
                                            showLogo
                                            hideLabel
                                            sponsor={hasSponsor ? <STopOfPageSponsor sponsorText="none" clickUrl={sponsorClickUrl} mobileImages={sponsorTopImageMobile} desktopImages={sponsorTopImageDesktop} /> : undefined}
                                        />
                                        {_streamingData?.hasCinemaShows && <SSeeOtherContentLink to={`/film/${_streamingData!.filmwebMovieId}`}>Filmen er tilgjengelig på kino. Klikk her for å se forestillinger</SSeeOtherContentLink>}
                                    </>}


                                </SProgramContainer>


                                {!isAdFree && <STopAd targetId='FW_topp_omtale_v2' />}

                                <MoviePoster cmsPoster={cmsDocument?.mainPosterV2}
                                    filminfoPosters={_streamingData?.imagesPoster as (ImageVersionType[] | null | undefined)}
                                    filmTitle={(cmsDocument?.title ?? _streamingData?.title)!}
                                    sanityPosterUrl={_streamingData?.sanityImagePosterUrl}
                                    isStreamingContext={isStreamingContext} />

                                {kinoklubbMovie && <KKPromo kkMovie={kinoklubbMovie} />}

                                <Ratings reviews={cmsDocument?.reviews} edi={cmsDocument?.mainVersionEDI} streamingId={_streamingData?.id} />

                                {!isAdFree && <UserReviews edi={cmsDocument?.mainVersionEDI} streamingId={_streamingData?.id} title={(cmsDocument?.title ?? _streamingData?.title)!} ref={reviewsListRef} />}

                                <SFacts cmsDocument={cmsDocument} streamingData={_streamingData} isStreaming={isStreamingContext} />

                                <FilmTexts isStreamingContext={isStreamingContext} cmsDocument={cmsDocument} streamingData={_streamingData} />

                                <ImageGalleryPlaceholder cmsDocument={cmsDocument} />


                            </SBodyContentWrapper>


                            {!isAdFree && <SBottomContentContainer>
                                <SMidAd targetId="FW_midt1_omtale_v2" />
                                <SRecommendedContainer>
                                    {!isStreamingContext && <SPopularMoviesList />}
                                    {isStreamingContext && (_streamingData?.similarContent?.length ?? 0) > 0 && <SSimilarStreamingTitles similarContent={_streamingData?.similarContent as (StreamingContentType[])} />}
                                </SRecommendedContainer>

                                {cmsDocument && <SFilmwebNews isStreamingContext={isStreamingContext} articles={cmsDocument!.otherRelations} padArticles={otherNews} />}

                                <SStreamingSearchBox toolbox={appData.streamingToolbox} />

                                <SBottomAd targetId="FW_midt2_omtale_v2" />

                            </SBottomContentContainer>}
                        </AdWrapper>
                    </MovieOptionalCinemaWrapper>
                </MovieOptionalLocationWrapper>
            </MovieActivityWrapper>

        </SFilmWrapper >
    </>);
}
//#endregion

type MovieOptionalWrapperProps = {
    children: React.ReactNode;
    isStreamingContext: boolean;
};

const MovieOptionalLocationWrapper = ({ children, isStreamingContext }: MovieOptionalWrapperProps) => {
    const WrapperElem = isStreamingContext ? Fragment : LocationWrapper;
    return <WrapperElem>{children}</WrapperElem>
}

const MovieOptionalCinemaWrapper = ({ children, isStreamingContext }: MovieOptionalWrapperProps) => {
    const WrapperElem = isStreamingContext ? Fragment : SelectedCinemaWrapper;
    return <WrapperElem>{children}</WrapperElem>
}


//#region [Styles]
const SInfoBox = styled(InfoBox)`
	margin: ${theme.maxContentPadding};
	width: calc(100% - 2 * ${theme.maxContentPadding});
	@media ${theme.mq.desktop} {
		margin: 20px auto;
		width: auto;
	}

	max-width: ${theme.maxContentWidth}px;
`;

const SFilmWrapper = styled.article`
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	z-index: 1;
	position: relative;

	@media ${theme.mq.desktop} {
		margin: 0 auto;
	}
`;


const SOverlay = styled.div`
	position: absolute;
	z-index: -1;
	width: 100vw;
	top: 0;
	left: 50%;
	transform: translateX(-50%);
	height: 90vw;
	background-image: linear-gradient(var(--backgroundalphaMobile), var(--backgroundcolor));
	//backdrop-filter: blur(3px);
	border-radius: var(--panel-borderradius) var(--panel-borderradius) 0 0;

	//transition: height 1s;

	@media ${theme.mq.desktop} {
		background-image: linear-gradient(to bottom, ${rgba(theme.bgColor, 0)} 0%, var(--backgroundalpha) 10%, var(--backgroundcolor) 63%);
		//backdrop-filter: blur(7px);
		height: 30vw;
		border-radius: 0;
	}

`;

const SHeaderContentWrapper = styled(ContentWrapper)`
	position: relative;
	margin: 0;
	@media ${theme.mq.desktop} {
		margin: 0 auto;
        border-top-right-radius: 24px;
        border-top-left-radius: 24px;
	}
`;

const SBodyContentWrapper = styled(ContentWrapper)`
	z-index: 1;
	position: relative;

	@media ${theme.mq.desktop} {
		grid-area: main;
		display: grid;
		column-gap: 65px;
		column-gap: MIN(
			3vw,
			65px
		); // use uppercase to avoid it interfering with sass function. (css is case-insentitive)
		row-gap: 10px;
		grid-template-columns: 2fr 4fr 3fr;
		grid-template-rows: auto auto auto auto auto auto;
		grid-template-areas:
			"program   program   topAd"
			"poster    kkpromo   topAd"
			"poster    ratings   topAd"
			"poster    text      facts"
			"poster    text      gallery"
			"poster    text   	 gallery"
			"reviews   reviews   reviews";
	}
`;


const SButtonsAndTitle = styled.div`

	@media ${theme.mq.desktop} {
		grid-area: header;
		display: flex;
		align-items: center;
	}
`;

const SProgramContainer = styled.div`
	display: flex;
	flex-direction: column;
	margin: 0 ${theme.maxContentPadding} 35px ${theme.maxContentPadding};


	@media ${theme.mq.desktop} {
		margin: 0 auto;
		grid-area: program;
		align-self: start;
		width: 100%;
	}
`;

const STopAd = styled(Ad)`
	width: 100vw;
	max-width: 100vw;
	margin: 0 0 35px 0;

	@media ${theme.mq.desktop} {
		width: 100%;
		max-width: 100%;
		margin: 0;
		margin-bottom: 1em;
		grid-area: topAd;
	}
`;

const SMidAd = styled(Ad)`
	width: 100vw;
	max-width: 100vw;
	margin: 40px -${theme.maxContentPadding};

	@media ${theme.mq.desktop} {
		width: 100%;
		max-width: 100%;
		margin: 40px auto;
	}
`;

const SBottomAd = styled(Ad)`
	width: 100vw;
	max-width: 100vw;
	margin: 20px -10px 20px -10px;
	@media ${theme.mq.desktop} {
		width: 100%;
		max-width: 100%;
		margin: 35px 0 35px 0;
	}
`;

const SFacts = styled(FilmFacts)`
	margin: 0 ${theme.maxContentPadding} 35px ${theme.maxContentPadding};
	@media ${theme.mq.desktop} {
		margin: 0;
		grid-area: facts;
	}
`;

const SBottomContentContainer = styled(ContentWrapper)`
	@media ${theme.mq.mobile} {
		margin-left: ${theme.maxContentPadding};
		margin-right: ${theme.maxContentPadding};
		width: auto;
	}
`;

const SFilmwebNews = styled(FilmwebNews)`
	margin: 0 0 ${theme.maxContentPadding} 0;
`;

const SSeeOtherContentLink = styled(FilmwebLink)`
	background-color: var(--textcolor);
	text-decoration: none;
	color: var(--buttoncolor);
	align-self: center;
	padding: 10px;
	border-radius: 20px;
	margin: 0 0 25px 0;

	&:hover {
		text-decoration: underline;
	}

	@media ${theme.mq.desktop} {
		padding: 20px 40px;
		border-radius: 40px;
		margin: 25px 12px 25px 12px;
	}
`;

const SRecommendedContainer = styled.div`
	padding-bottom: 50px;
	// the following width and max-width was necessary to prevent this box from forcing the page wider than the screen
	width: calc(100vw - 20px);
	max-width: calc(100vw - 20px);

	@media ${theme.mq.desktop} {
		//@media #{settings.$desktop} {//@media (orientation: landscape) {
		width: 100%;
		max-width: 100%;
	}
`;

const SPopularMoviesList = styled(PopularMoviesList)`
	width: calc(100vw - ${theme.maxContentPadding}); // this is necessary to make the right edge flush with the screen edge
	overflow: hidden;

	@media ${theme.mq.desktop} {
		width: auto;
	}
`;

const SSimilarStreamingTitles = styled(SimilarStreamingTitles)`
	width: calc(100vw - ${theme.maxContentPadding}); // this is necessary to make the right edge flush with the screen edge
	overflow: hidden;

	@media ${theme.mq.desktop} {
		width: auto;
	}
`;


const SStreamingSearchBox = styled(StreamingSearchBox)`
	width: 100vw;
	@media ${theme.mq.desktop} {
		//@media #{settings.$desktop} {//@media (orientation: landscape) {
		margin: 30px 0 0 0;
		width: 100%;
	}
`;

const STopOfPageSponsor = styled(TopOfPageSponsor)`
    margin-bottom: 20px;
    @media ${theme.mq.desktop} {
        margin-bottom: 0;
        margin-left: auto;
        order: 2;
        flex: 0 0 50%;
        max-width: 400px;

        .topOfPageSponsorInnerContainer {
            margin-bottom: 0;

        }
    }
`;

//#endregion

//#region [Other]
const ADPROXIMITY = 0;

const ADS: AdUnit[] = [
    { auId: "0000000000065af9", auW: "640", auH: "400", targetId: "FW_midt2_omtale_v2" },
    //{ auId: "3832e8", auW: "640", auH: "400", targetId: "FW_midt2_omtale_v2" }, // SAN-509
    { auId: "0000000000065aeb", auW: "640", auH: "400", targetId: "FW_midt1_omtale_v2" },
    //{ auId: "3832e7", auW: "640", auH: "400", targetId: "FW_midt1_omtale_v2" }, // SAN-509
    { auId: '0000000000065aea', auW: '375', auH: '235', targetId: 'FW_topp_omtale_v2' },
    //{ auId: '383249', auW: '375', auH: '235', targetId: 'FW_topp_omtale_v2' }, // SAN-509
    { auId: '0000000000248ebf', auW: '1px', auH: '1px', targetId: 'FW_sg_search_box' },
    //{ auId: '365246', auW: '1px', auH: '1px', targetId: 'SponsorTrackingPixel' },
];

const SG_ADS: AdUnit[] = [
    { auId: "00000000000869f0", auW: "640", auH: "400", targetId: "FW_midt2_omtale_v2" },
    { auId: "00000000000869ef", auW: "640", auH: "400", targetId: "FW_midt1_omtale_v2" },
    { auId: '00000000000869ee', auW: '375', auH: '235', targetId: 'FW_topp_omtale_v2' },
    { auId: '0000000000248ebf', auW: '1px', auH: '1px', targetId: 'FW_sg_search_box' },
    //{ auId: '365245', auW: '1px', auH: '1px', targetId: 'SponsorTrackingPixel' },
];

//#endregion