import theme from "src/components/themes/DefaultTheme";
import IFranchiseBlock from "src/lib/types/IFranchiseBlock";
import styled from "src/lib/styles/css";
import { StreamingContentType } from "src/lib/movieinfo/movieinfotypes";
import PosterRow from "src/components/posterrow/PosterRow";

//#region [Props]
type FranchiseBlockContentProps = {
    franchiseBlock: IFranchiseBlock;
    contentType: "series" | "movies";
    streamingData?: StreamingContentType[] | null;
};
//#endregion

//#region [Component]
export default function FranchiseBlockContent({ franchiseBlock, contentType, streamingData }: FranchiseBlockContentProps) {
    const contentList = (contentType === "movies" ? franchiseBlock.franchise.movies?.slice(0, 6) : franchiseBlock.franchise.series?.slice(0, 6))?.map(content => {
        if (content._type === "streamingContent") {
            return streamingData?.find(c => c.id === content.streamingId);

        }
        return content;
    }).filter(c => !!c);
    const heading = contentType === "movies" ? (franchiseBlock.franchise.moviesHeading ?? "Filmer") : (franchiseBlock.franchise.seriesHeading ?? "Serier");

    if (!contentList || contentList.length === 0) return null;

    return <SFranchiseBlockContent>
        <SContentHeading>{heading}</SContentHeading>
        <div>
            <PosterRow posterList={contentList} max={6} renderPlaceholders longTitle hideKinoklubbBanner/>
        </div>
    </SFranchiseBlockContent>;
}
//#endregion

//#region [Styles]
const SFranchiseBlockContent = styled.div`
    margin-bottom: 1em;
`;
const SContentHeading = styled.h3`
    font-size: ${theme.fonts.tittelXS.size};
    margin: 0;

    @media ${theme.mq.desktop} {
        margin: 0 !important;
        font-size: ${theme.fonts.tittelS.size};
    }
`;
//#endregion