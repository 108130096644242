import ButtonLink from "src/components/buttonlink/ButtonLink";
import { movieListPosterQuery } from "src/components/forsiden/StreamingPosterRowWithSelfLoad";
import LoadingIndicator from "src/components/loadingindicator/LoadingIndicator";
import FranchiseBlockBanner from "src/components/richtext/franchise/FranchiseBlockBanner";
import FranchiseBlockContent from "src/components/richtext/franchise/FranchiseBlockContent";
import theme from "src/components/themes/DefaultTheme";
import { buildFilminfoQueryKey, filminfoRequest, useFilminfo } from "src/lib/client/useFilminfo";
import { StreamingContentType } from "src/lib/movieinfo/movieinfotypes";
import Prefetch from "src/lib/server/reactQueryPrefetch";
import styled from "src/lib/styles/css";
import IFranchiseBlock from "src/lib/types/IFranchiseBlock";

//#region [Props]
type FranchiseBlockProps = {
    value: IFranchiseBlock;
};
//#endregion

export const prefetch: Prefetch = async (queryClient, params) => queryClient.prefetchQuery({
    queryKey: buildFilminfoQueryKey(movieListPosterQuery, params),
    queryFn: filminfoRequest
});

//#region [Component]
export default function FranchiseBlock({ value }: FranchiseBlockProps) {

    // filter movies and series that are "IStreamingContent" so we can fetch the data
    const streamingIds = [
        ...(value.franchise.movies?.filter(m => !!m.streamingId)?.map(m => m.streamingId) ?? []),
        ...(value.franchise.series?.filter(m => !!m.streamingId)?.map(m => m.streamingId) ?? [])
    ];
    // this should ideally be preloaded
    const { fiData, fiLoading } = useFilminfo(movieListPosterQuery, { streamingIds }, { active: !!streamingIds.length });
    const strContentList = fiData?.streamingQuery?.getStreamingContentList as (StreamingContentType[] | null | undefined);

    const style: any = {
        backgroundColor: "var(--franchise-bg-color)",
        color: value.textColor?.hex ?? theme.textColor,
        ["--franchise-text-color"]: value.textColor?.hex ?? theme.textColor,
        ["--textcolor"]: value.textColor?.hex ?? theme.textColor,
        ["--franchise-bg-color"]: value.bgColor?.hex ?? theme.palette.lilla4,
    };

    return <SFranchiseBlock style={style}>
        <FranchiseBlockBanner franchiseBlock={value} />
        {fiLoading && <LoadingIndicator size="large" />}
        {!fiLoading && <>
            <FranchiseBlockContent franchiseBlock={value} contentType="movies" streamingData={strContentList} />
            <FranchiseBlockContent franchiseBlock={value} contentType="series" streamingData={strContentList} />
        </>}
        <SButtonLink href={`/franchise/${value.franchise.slug.current}`}>Se mer på vår samleside for {value.franchise.title}</SButtonLink>
    </SFranchiseBlock>;
}
//#endregion

//#region [Styles]
const SFranchiseBlock = styled.aside`
    width: calc(100% - 9px);
    max-width: calc(100vw - ${theme.maxContentPadding} * 2);
    margin: 0 auto;
    border-radius: calc(${theme.stdBorderRadius} / 2);
    padding: ${theme.stdBorderRadius};
    border: 3px solid var(--franchise-text-color);
    box-shadow: 0 0 0 6px var(--franchise-bg-color);
    display: flex;
    flex-direction: column;
`;

const SButtonLink = styled(ButtonLink)`
    color: var(--franchise-text-color) !important;
    border: 3px solid var(--franchise-text-color);
    background: transparent;
    display: inline-block;
    margin: 0 auto;

    @media ${theme.mq.desktop} {
        font-size: ${theme.fonts.bodytextL.size};
    }

`;

//#endregion