import IFranchiseBlock from "src/lib/types/IFranchiseBlock";
import styled from "src/lib/styles/css";
import Picture, { PictureFallbackImage } from "src/components/images/Picture";
import CmsImageForAssetRef from "src/components/images/CmsImageForAssetRef";
import CmsImage from "src/components/images/CmsImage";
import theme from "src/components/themes/DefaultTheme";

//#region [Props]
type FranchiseBlockBannerProps = {
    franchiseBlock: IFranchiseBlock;
};
//#endregion

//#region [Component]
export default function FranchiseBlockBanner({ franchiseBlock }: FranchiseBlockBannerProps) {
    const franchise = franchiseBlock.franchise;
    const showBanner = franchiseBlock.banner === "bannerAndLogo" || franchiseBlock.banner === "bannerOnly";
    const mobileImage = franchiseBlock.franchise.mobileBanner;
    const desktopImage = franchiseBlock.franchise.desktopBanner;
    const showLogo = franchiseBlock.banner === "bannerAndLogo" && !!franchiseBlock.franchise.logo?.asset;

    if( !showLogo && !showBanner ) return null;

    return <SFranchiseBlockBanner>
        {showBanner && <Picture>
            <PictureFallbackImage cmsImage={mobileImage?.asset ? mobileImage : desktopImage} alt={franchise.title} />
        </Picture>}
        {showLogo && <SLogoImage image={franchiseBlock.franchise.logo!} alt={`Logo for '${franchiseBlock.franchise.title}'`} width={400} unoptimized />}

    </SFranchiseBlockBanner>;
}
//#endregion

//#region [Styles]
const SFranchiseBlockBanner = styled.div`
    position: relative;
    margin-bottom: 10px;

    @media ${theme.mq.desktop} {
        margin-bottom: 15px;
    }
`;

const SLogoImage = styled(CmsImage)`
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    width: 50%;
`;
//#endregion